const appVersion = '1.0.6';

const CATEGORY_ANIMALS = 'Djur';
const CATEGORY_BUILDINGS = 'Byggnader';
const CATEGORY_NATURE = 'Natur';
const CATEGORY_PEOPLE = 'Människor och kläder';
const CATEGORY_SIGNS = 'Skyltar';
const CATEGORY_THINGS = 'Saker';
const CATEGORY_VEHICLES = 'Fordon';

const CATEGORIES = [
    CATEGORY_ANIMALS,
    CATEGORY_BUILDINGS,
    CATEGORY_NATURE,
    CATEGORY_PEOPLE,
    CATEGORY_SIGNS,
    CATEGORY_THINGS,
    CATEGORY_VEHICLES,
];

const CATEGORY_NAMES = {
    'CATEGORY_ANIMALS': CATEGORY_ANIMALS,
    'CATEGORY_BUILDINGS': CATEGORY_BUILDINGS,
    'CATEGORY_NATURE': CATEGORY_NATURE,
    'CATEGORY_PEOPLE': CATEGORY_PEOPLE,
    'CATEGORY_SIGNS': CATEGORY_SIGNS,
    'CATEGORY_THINGS': CATEGORY_THINGS,
    'CATEGORY_VEHICLES': CATEGORY_VEHICLES,
};

const OPTIONS = [
    //SKYLTAR
    {
        name: 'sign_40',
        image: 'sign_40.png?v=' +appVersion,
        title: '40-skylt',
        audio: '40-skylt.mp3?v=' +appVersion,
        categories: [CATEGORY_SIGNS]
    },  
    {
        name: 'sign_50',
        image: 'sign_50.png?v=' +appVersion,
        title: '50-skylt',
        audio: '50-skylt.mp3?v=' +appVersion,
        categories: [CATEGORY_SIGNS]
    },      
    {
        name: 'sign_60',
        image: 'sign_60.png?v=' +appVersion,
        title: '60-skylt',
        audio: '60-skylt.mp3?v=' +appVersion,
        categories: [CATEGORY_SIGNS]
    },      
    {
        name: 'sign_70',
        image: 'sign_70.png?v=' +appVersion,
        title: '70-skylt',
        audio: '70-skylt.mp3?v=' +appVersion,
        categories: [CATEGORY_SIGNS]
    },      
    {
        name: 'sign_80',
        image: 'sign_80.png?v=' +appVersion,
        title: '80-skylt',
        audio: '80-skylt.mp3?v=' +appVersion,
        categories: [CATEGORY_SIGNS]
    },      
    {
        name: 'sign_90',
        image: 'sign_90.png?v=' +appVersion,
        title: '90-skylt',
        audio: '90-skylt.mp3?v=' +appVersion,
        categories: [CATEGORY_SIGNS]
    },    
    {
        name: 'sign_motor_road',
        image: 'sign_motor_road.png?v=' +appVersion,
        title: 'Motorväg',
        audio: 'Motorvag.mp3?v=' +appVersion,
        categories: [CATEGORY_SIGNS]
    },    
    {
        name: 'sign_roundabout',
        image: 'sign_roundabout.png?v=' +appVersion,
        title: 'Rondell',
        audio: 'Rondell.mp3?v=' +appVersion,
        categories: [CATEGORY_SIGNS]
    },
    {
        name: 'sign_parking',
        image: 'sign_parking.png?v=' +appVersion,
        title: 'Parkerings-skylt',
        audio: 'Parkerings-skylt.mp3?v=' +appVersion,
        categories: [CATEGORY_SIGNS]
    },
    {
        name: 'sign_crossroad',
        image: 'sign_crossroad.png?v=' +appVersion,
        title: 'Övergångs-ställe',
        audio: 'Overgangsstalle.mp3?v=' +appVersion,
        categories: [CATEGORY_SIGNS]
    },
    {
        name: 'sign_resting_place',
        image: 'sign_resting_place.png?v=' +appVersion,
        title: 'Rastplats',
        audio: 'Rastplats.mp3?v=' +appVersion,
        categories: [CATEGORY_SIGNS]
    },      
    {
        name: 'sign_stop',
        image: 'sign_stop.png?v=' +appVersion,
        title: 'Stoppskylt',
        audio: 'Stoppskylt.mp3?v=' +appVersion,
        categories: [CATEGORY_SIGNS]
    },    
    {
        name: 'sign_main_route',
        image: 'sign_main_route.png?v=' +appVersion,
        title: 'Huvudled',
        audio: 'Huvudled.mp3?v=' +appVersion,
        categories: [CATEGORY_SIGNS]
    },        
    {
        name: 'sign_duty_to_give_away',
        image: 'sign_duty_to_give_away.png?v=' +appVersion,
        title: 'Väjningsplikt',
        audio: 'Vajningsplikt.mp3?v=' +appVersion,
        categories: [CATEGORY_SIGNS]
    }, 
    {
        name: 'sign_road_work',
        image: 'sign_road_work.png?v=' +appVersion,
        title: 'Vägarbetsskylt',
        audio: 'Vagarbetsskylt.mp3?v=' +appVersion,
        categories: [CATEGORY_SIGNS]
    },         
    //DJUR    
    {
        name: 'animal_bird',
        image: 'animal_bird.png?v=' +appVersion,
        title: 'Fågel',
        audio: 'Fagel.mp3?v=' +appVersion,
        categories: [CATEGORY_ANIMALS]
    },     
    {
        name: 'animal_dog',
        image: 'animal_dog.png?v=' +appVersion,
        title: 'Hund',
        audio: 'Hund.mp3?v=' +appVersion,
        categories: [CATEGORY_ANIMALS]
    },      
    {
        name: 'animal_cat',
        image: 'animal_cat.png?v=' +appVersion,
        title: 'Katt',
        audio: 'Katt.mp3?v=' +appVersion,
        categories: [CATEGORY_ANIMALS]
    },      
    {
        name: 'animal_horse',
        image: 'animal_horse.png?v=' +appVersion,
        title: 'Häst',
        audio: 'Hast.mp3?v=' +appVersion,
        categories: [CATEGORY_ANIMALS]
    },      
    {
        name: 'animal_sheep',
        image: 'animal_sheep.png?v=' +appVersion,
        title: 'Får',
        audio: 'Far.mp3?v=' +appVersion,
        categories: [CATEGORY_ANIMALS]
    },      
    {
        name: 'animal_cow',
        image: 'animal_cow.png?v=' +appVersion,
        title: 'Ko',
        audio: 'Ko.mp3?v=' +appVersion,
        categories: [CATEGORY_ANIMALS]
    },     
    //BYGGNADER  
    {
        name: 'building_church',
        image: 'building_church.png?v=' +appVersion,
        title: 'Kyrka',
        audio: 'Kyrka.mp3?v=' +appVersion,
        categories: [CATEGORY_BUILDINGS]
    },         
    {
        name: 'building_grocery_store',
        image: 'building_grocery_store.png?v=' +appVersion,
        title: 'Mataffär',
        audio: 'Mataffar.mp3?v=' +appVersion,
        categories: [CATEGORY_BUILDINGS]
    },   
    {
        name: 'building_bridge',
        image: 'building_bridge.png?v=' +appVersion,
        title: 'Bro',
        audio: 'Bro.mp3?v=' +appVersion,
        categories: [CATEGORY_BUILDINGS]
    },   
    {
        name: 'building_bridge_over_water',
        image: 'building_bridge_over_water.png?v=' +appVersion,
        title: 'Bro över vatten',
        audio: 'Bro over vatten.mp3?v=' +appVersion,
        categories: [CATEGORY_BUILDINGS]
    }, 
    {
        name: 'building_tunnel',
        image: 'building_tunnel.png?v=' +appVersion,
        title: 'Tunnel',
        audio: 'Tunnel.mp3?v=' +appVersion,
        categories: [CATEGORY_BUILDINGS]
    },        
    {
        name: 'building_restaurant',
        image: 'building_restaurant.png?v=' +appVersion,
        title: 'Restaurang',
        audio: 'Restaurang.mp3?v=' +appVersion,
        categories: [CATEGORY_BUILDINGS]
    },   
    {
        name: 'building_mcdonalds',
        image: 'building_mcdonalds.png?v=' +appVersion,
        title: 'McDonalds',
        audio: 'McDonalds.mp3?v=' +appVersion,
        categories: [CATEGORY_BUILDINGS]
    },   
    {
        name: 'building_gasstation',
        image: 'building_gasstation.png?v=' +appVersion,
        title: 'Bensinmack',
        audio: 'Bensinmack.mp3?v=' +appVersion,
        categories: [CATEGORY_BUILDINGS]
    },   
    {
        name: 'building_hotel',
        image: 'building_hotel.png?v=' +appVersion,
        title: 'Hotell',
        audio: 'Hotell.mp3?v=' +appVersion,
        categories: [CATEGORY_BUILDINGS]
    },   
    {
        name: 'building_pizza',
        image: 'building_pizza.png?v=' +appVersion,
        title: 'Pizzeria',
        audio: 'Pizzeria.mp3?v=' +appVersion,
        categories: [CATEGORY_BUILDINGS]
    },   
    {
        name: 'building_red_house',
        image: 'building_red_house.png?v=' +appVersion,
        title: 'Rött hus',
        audio: 'Rott hus.mp3?v=' +appVersion,
        categories: [CATEGORY_BUILDINGS]
    },   
    {
        name: 'building_yellow_house',
        image: 'building_yellow_house.png?v=' +appVersion,
        title: 'Gult hus',
        audio: 'Gult hus.mp3?v=' +appVersion,
        categories: [CATEGORY_BUILDINGS]
    },   
    {
        name: 'building_white_house',
        image: 'building_white_house.png?v=' +appVersion,
        title: 'Vitt hus',
        audio: 'Vitt hus.mp3?v=' +appVersion,
        categories: [CATEGORY_BUILDINGS]
    },   
    {
        name: 'building_playground',
        image: 'building_playground.png?v=' +appVersion,
        title: 'Lekplats',
        audio: 'Lekplats.mp3?v=' +appVersion,
        categories: [CATEGORY_BUILDINGS]
    },   
    //FORDON  
    {
        name: 'vehicle_truck',
        image: 'vehicle_truck.png?v=' +appVersion,
        title: 'Lastbil',
        audio: 'Lastbil.mp3?v=' +appVersion,
        categories: [CATEGORY_VEHICLES]
    },         
    {
        name: 'vehicle_truck_x2',
        image: 'vehicle_truck_x2.png?v=' +appVersion,
        title: '2 lastbilar på rad',
        audio: '2 lastbilar på rad.mp3?v=' +appVersion,
        categories: [CATEGORY_VEHICLES]
    },         
    {
        name: 'vehicle_bus',
        image: 'vehicle_bus.png?v=' +appVersion,
        title: 'Buss',
        audio: 'Buss.mp3?v=' +appVersion,
        categories: [CATEGORY_VEHICLES]
    },         
    {
        name: 'vehicle_taxi',
        image: 'vehicle_taxi.png?v=' +appVersion,
        title: 'Taxi',
        audio: 'Taxi.mp3?v=' +appVersion,
        categories: [CATEGORY_VEHICLES]
    },         
    {
        name: 'vehicle_motorbike',
        image: 'vehicle_motorbike.png?v=' +appVersion,
        title: 'Motorcykel',
        audio: 'Motorcykel.mp3?v=' +appVersion,
        categories: [CATEGORY_VEHICLES]
    },     
    {
        name: 'vehicle_bicycle',
        image: 'vehicle_bicycle.png?v=' +appVersion,
        title: 'Cykel',
        audio: 'Cykel.mp3?v=' +appVersion,
        categories: [CATEGORY_VEHICLES]
    },     
    {
        name: 'vehicle_train',
        image: 'vehicle_train.png?v=' +appVersion,
        title: 'Tåg',
        audio: 'Tag.mp3?v=' +appVersion,
        categories: [CATEGORY_VEHICLES]
    },     
    {
        name: 'vehicle_police',
        image: 'vehicle_police.png?v=' +appVersion,
        title: 'Polis',
        audio: 'Polis.mp3?v=' +appVersion,
        categories: [CATEGORY_VEHICLES]
    },     
    {
        name: 'vehicle_fire_truck',
        image: 'vehicle_fire_truck.png?v=' +appVersion,
        title: 'Brandbil',
        audio: 'Brandbil.mp3?v=' +appVersion,
        categories: [CATEGORY_VEHICLES]
    },   
    {
        name: 'vehicle_ambulance',
        image: 'vehicle_ambulance.png?v=' +appVersion,
        title: 'Ambulans',
        audio: 'Ambulans.mp3?v=' +appVersion,
        categories: [CATEGORY_VEHICLES]
    },
    {
        name: 'vehicle_emergency',
        image: 'vehicle_emergency.png?v=' +appVersion,
        title: 'Utrycknings-fordon',
        audio: 'Utrycknings-fordon.mp3?v=' +appVersion,
        categories: [CATEGORY_VEHICLES]
    },
    {
        name: 'vehicle_roof_box',
        image: 'vehicle_roof_box.png?v=' +appVersion,
        title: 'Takbox',
        audio: 'Takbox.mp3?v=' +appVersion,
        categories: [CATEGORY_VEHICLES]
    },          
    {
        name: 'vehicle_red_car',
        image: 'vehicle_red_car.png?v=' +appVersion,
        title: 'Röd bil',
        audio: 'Rod bil.mp3?v=' +appVersion,
        categories: [CATEGORY_VEHICLES]
    },                 
    {
        name: 'vehicle_yellow_car',
        image: 'vehicle_yellow_car.png?v=' +appVersion,
        title: 'Gul bil',
        audio: 'Gul bil.mp3?v=' +appVersion,
        categories: [CATEGORY_VEHICLES]
    },                 
    {
        name: 'vehicle_blue_car',
        image: 'vehicle_blue_car.png?v=' +appVersion,
        title: 'Blå bil',
        audio: 'Bla bil.mp3?v=' +appVersion,
        categories: [CATEGORY_VEHICLES]
    },                 
    {
        name: 'vehicle_black_car',
        image: 'vehicle_black_car.png?v=' +appVersion,
        title: 'Svart bil',
        audio: 'Svart bil.mp3?v=' +appVersion,
        categories: [CATEGORY_VEHICLES]
    },                 
    {
        name: 'vehicle_silver_car',
        image: 'vehicle_silver_car.png?v=' +appVersion,
        title: 'Silvrig bil',
        audio: 'Silvrig bil.mp3?v=' +appVersion,
        categories: [CATEGORY_VEHICLES]
    },                 
    {
        name: 'vehicle_white_car',
        image: 'vehicle_white_car.png?v=' +appVersion,
        title: 'Vit bil',
        audio: 'Vit bil.mp3?v=' +appVersion,
        categories: [CATEGORY_VEHICLES]
    },                 
    {
        name: 'vehicle_tractor',
        image: 'vehicle_tractor.png?v=' +appVersion,
        title: 'Traktor',
        audio: 'Traktor.mp3?v=' +appVersion,
        categories: [CATEGORY_VEHICLES]
    },                 
    {
        name: 'vehicle_excavator',
        image: 'vehicle_excavator.png?v=' +appVersion,
        title: 'Grävskopa',
        audio: 'Gravskopa.mp3?v=' +appVersion,
        categories: [CATEGORY_VEHICLES]
    },                 
    {
        name: 'vehicle_boat',
        image: 'vehicle_boat.png?v=' +appVersion,
        title: 'Båt',
        audio: 'Bat.mp3?v=' +appVersion,
        categories: [CATEGORY_VEHICLES]
    },                 
    {
        name: 'vehicle_motorvan',
        image: 'vehicle_motorvan.png?v=' +appVersion,
        title: 'Husbil',
        audio: 'Husbil.mp3?v=' +appVersion,
        categories: [CATEGORY_VEHICLES]
    },                 
    {
        name: 'vehicle_caravan',
        image: 'vehicle_caravan.png?v=' +appVersion,
        title: 'Husvagn',
        audio: 'Husvagn.mp3?v=' +appVersion,
        categories: [CATEGORY_VEHICLES]
    },                 
    {
        name: 'vehicle_car_with_trailer',
        image: 'vehicle_car_with_trailer.png?v=' +appVersion,
        title: 'Bil med släp',
        audio: 'Bil med slap.mp3?v=' +appVersion,
        categories: [CATEGORY_VEHICLES]
    },                 
    //MÄNNISKOR  
    {
        name: 'people_excersising',
        image: 'people_excersising.png?v=' +appVersion,
        title: 'Tränar',
        audio: 'Tranar.mp3?v=' +appVersion,
        categories: [CATEGORY_PEOPLE]
    }, 
    {
        name: 'people_backpack',
        image: 'people_backpack.png?v=' +appVersion,
        title: 'Ryggsäck',
        audio: 'Ryggsack.mp3?v=' +appVersion,
        categories: [CATEGORY_PEOPLE]
    },
    {
        name: 'people_jeans',
        image: 'people_jeans.png?v=' +appVersion,
        title: 'Jeansbyxor',
        audio: 'Jeansbyxor.mp3?v=' +appVersion,
        categories: [CATEGORY_PEOPLE]
    },    
    {
        name: 'people_dress',
        image: 'people_dress.png?v=' +appVersion,
        title: 'Klänning',
        audio: 'Klanning.mp3?v=' +appVersion,
        categories: [CATEGORY_PEOPLE]
    },
    {
        name: 'people_basebal_hat',
        image: 'people_basebal_hat.png?v=' +appVersion,
        title: 'Keps',
        audio: 'Keps.mp3?v=' +appVersion,
        categories: [CATEGORY_PEOPLE]
    },      
    //NATUR
    {
        name: 'nature_tree',
        image: 'nature_tree.png?v=' +appVersion,
        title: 'Träd',
        audio: 'Trad.mp3?v=' +appVersion,
        categories: [CATEGORY_NATURE]
    },
    {
        name: 'nature_flower',
        image: 'nature_flower.png?v=' +appVersion,
        title: 'Blomma',
        audio: 'Blomma.mp3?v=' +appVersion,
        categories: [CATEGORY_NATURE]
    },    
    {
        name: 'nature_river',
        image: 'nature_river.png?v=' +appVersion,
        title: 'Flod eller å',
        audio: 'Flod eller a.mp3?v=' +appVersion,
        categories: [CATEGORY_NATURE]
    },
    {
        name: 'nature_golf_course',
        image: 'nature_golf_course.png?v=' +appVersion,
        title: 'Golfbana',
        audio: 'Golfbana.mp3?v=' +appVersion,
        categories: [CATEGORY_NATURE]
    },             
    //THINGS  
    {
        name: 'things_clock',
        image: 'things_clock.png?v=' +appVersion,
        title: 'Klocka',
        audio: 'Klocka.mp3?v=' +appVersion,
        categories: [CATEGORY_THINGS]
    },
    {
        name: 'things_icecream',
        image: 'things_icecream.png?v=' +appVersion,
        title: 'Glass',
        audio: 'Glass.mp3?v=' +appVersion,
        categories: [CATEGORY_THINGS]
    },
    {
        name: 'things_flag',
        image: 'things_flag.png?v=' +appVersion,
        title: 'Flagga',
        audio: 'Flagga.mp3?v=' +appVersion,
        categories: [CATEGORY_THINGS]
    },
    {
        name: 'things_swedish_flag',
        image: 'things_swedish_flag.png?v=' +appVersion,
        title: 'Svensk flagga',
        audio: 'Svensk flagga.mp3?v=' +appVersion,
        categories: [CATEGORY_THINGS]
    },
    {
        name: 'things_stroller',
        image: 'things_stroller.png?v=' +appVersion,
        title: 'Barnvagn',
        audio: 'Barnvagn.mp3?v=' +appVersion,
        categories: [CATEGORY_THINGS]
    },
    {
        name: 'things_umbrella',
        image: 'things_umbrella.png?v=' +appVersion,
        title: 'Paraply',
        audio: 'Paraply.mp3?v=' +appVersion,
        categories: [CATEGORY_THINGS]
    },
    {
        name: 'things_trashcan',
        image: 'things_trashcan.png?v=' +appVersion,
        title: 'Soptunna',
        audio: 'Soptunna.mp3?v=' +appVersion,
        categories: [CATEGORY_THINGS]
    },
    {
        name: 'things_soccer_goal',
        image: 'things_soccer_goal.png?v=' +appVersion,
        title: 'Fotbollsmål',
        audio: 'Fotbollsmal.mp3?v=' +appVersion,
        categories: [CATEGORY_THINGS]
    },
    {
        name: 'things_traffic_sign',
        image: 'things_traffic_sign.png?v=' +appVersion,
        title: 'Trafikljus',
        audio: 'Trafikljus.mp3?v=' +appVersion,
        categories: [CATEGORY_THINGS]
    },
    {
        name: 'things_fence',
        image: 'things_fence.png?v=' +appVersion,
        title: 'Staket',
        audio: 'Staket.mp3?v=' +appVersion,
        categories: [CATEGORY_THINGS]
    },
    {
        name: 'things_wind_turbines',
        image: 'things_wind_turbines.png?v=' +appVersion,
        title: 'Vindkraftverk',
        audio: 'Vindkraftverk.mp3?v=' +appVersion,
        categories: [CATEGORY_THINGS]
    },
    {
        name: 'things_electric_wires',
        image: 'things_electric_wires.png?v=' +appVersion,
        title: 'Elledning',
        audio: 'Elledning.mp3?v=' +appVersion,
        categories: [CATEGORY_THINGS]
    },
    {
        name: 'things_hay_bale',
        image: 'things_hay_bale.jpg',
        title: 'Höbal',
        audio: 'Hobal.mp3?v=' +appVersion,
        categories: [CATEGORY_THINGS]
    },   
    {
        name: 'things_phone',
        image: 'things_phone.png?v=' +appVersion,
        title: 'Mobiltelefon',
        audio: 'Mobiltelefon.mp3?v=' +appVersion,
        categories: [CATEGORY_THINGS]
    },  
    {
        name: 'things_trampoline',
        image: 'things_trampoline.png?v=' +appVersion,
        title: 'Studsmatta',
        audio: 'Studsmatta.mp3?v=' +appVersion,
        categories: [CATEGORY_THINGS]
    },
];

export default {
    CATEGORIES: CATEGORIES,
    CATEGORY_NAMES: CATEGORY_NAMES,
    OPTIONS: OPTIONS,
}
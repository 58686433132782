<template>
    <div>
        <h1 class="print-header show-for-print-only">Barnens bilbingo</h1>
        <div v-if="gameSquares.length == 0">
            <router-link class="banner-link" to="/"><i class="fas fa-play"></i> <div><strong>Skapa ny bingobricka!</strong><br>Du har inte skapat något bricka än.</div></router-link>
        </div>
        <div class="volume-settings hide-for-print" v-if="gameSquares.length > 0">
            <button class="toggle-mute-btn" v-on:click="toggleMute" title="Stäng av/slå på ljud">
                <i v-if="volumeMute" class="fas fa-volume-mute"></i>
                <i v-if="!volumeMute" class="fas fa-volume-up"></i>
            </button>
        </div>
        <div class="bingo-squares-container">
            <div class="bingo-squares" v-bind:class="[superBingo ? 'bingo-squares--super-bingo' : '']" :data-columns="size">
                <div v-for="i in size" :key="i" class="bingo-column">
                    <BingoSquare v-for="j in size" 
                        :key="j" 
                        :row="j" 
                        :col="i" 
                        :show-title="titleHelpActive" 
                        :check-type="'check'"
                        :square-data="getSquaresDataForSquare(j, i)"
                        :square-index="squareIndex(j, i)"
                        :initial-checked="isSquareChecked(j, i)" 
                        @onClick="onBingoSquareClick"
                    />
                </div>
            </div>
        </div>
    <!-- Rows: {{bingoRows}}<br>
    Cols: {{bingoCols}}<br>
    Diagonal: {{bingoDiagonals}}<br>
    SuperBingo: {{superBingo}}<br>
    Antal bingorader: {{numberOfBingos}}<br> -->

    <div v-if="gameSquares.length > 0">
        <div class="help-container hide-for-print">
            <div class="help-buttons">
                <button class="help-button speaker-button" title="Spela upp ljud" v-bind:class="[audioHelpActive ? 'help-button--active' : '']" v-on:click="toggleAudioHelpActive">
                    <img src="/img/speaker.png" alt="Högtalare">
                </button>
                <button class="help-button title-button" title="Visa text" v-bind:class="[titleHelpActive ? 'help-button--active' : '']" v-on:click="toggleTitleHelpActive">
                    ABC
                </button>
            </div>
            <div v-if="audioHelpActive" class="speaker-help-text">
                <img src="/img/hand.png" alt="Hand som klickar"> Tryck på en ruta för att höra vad det är.
            </div>
        </div>
     
        <div class="color-selector-group hide-for-print">
            <button v-for="color in availableColors" :key="color.name" v-on:click="setCheckColor(color.name)" class="color-selector" v-bind:class="[isCurrentCheckColor(color.name) ? 'color-selector--active' : '']" :style="{'background-color':color.fill, 'border-color':color.border}">
                <i v-if="isCurrentCheckColor(color.name)" class="fa fa-paint-brush"></i>
                

                <!-- <svg v-if="isCurrentCheckColor(color.name)" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2" class="bingo-square__check">                  
                  <polyline class="path check" fill="none" stroke="#fff" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 "/>
              </svg>     -->
            </button>            
        </div>

       <div class="bingo-information hide-for-print">
            Bingorader: {{numberOfBingos}}
        </div>   
    </div>

        <div class="bingo-text hide-for-print" :class="bingoTextClass">
            <span class="b">B</span>
            <span class="i">I</span>
            <span class="n">N</span>
            <span class="g">G</span>
            <span class="o">O</span>
        </div>

        <p class="ingress show-for-print-only" style="margin-top:100px">Skriv ut fler brickor eller spela online på www.barnensbilbingo.se.</p>
    </div>
</template>

<script>


// @ is an alias to /src
import BingoSquare from '@/components/BingoSquare.vue'
import ColorSettings from '../color-settings.js'

export default {
  name: 'Bingo',
  data: function(){
      return {
          bingoTextClass: '',
          sound: new Audio(),
          checkSound: new Audio()
      }
  },
  components: {
    BingoSquare
  },
  computed: {
    size(){
      if(this.gameSquares){
        return Math.sqrt(this.gameSquares.length);
      }
      return 0;
    },
    state(){
      return this.$store.state;
    },
    volumeMute(){
      return this.$store.state.settings.volumeMute;
    },    
    gameSquares(){
      return this.$store.state.game.squares;
    },
    checkedSquares(){
      return this.$store.state.game.checkedSquares;
    },
    bingoRows(){
        return this.$store.getters.bingoRows;
    },
    bingoCols(){
        return this.$store.getters.bingoCols;
    },
    bingoDiagonals(){
        return this.$store.getters.bingoDiagonals;
    },
    superBingo(){
        return this.$store.getters.bingoRows.length === this.size;
    },
    numberOfBingos(){
        if(this.checkedSquares.length === 0){
            return 0;
        }        
        return this.bingoRows.length + this.bingoCols.length +this.bingoDiagonals.length;
    },
    numberOfBingoTimesWord(){
        return this.numberOfBingos == 1 ? 'gång' : 'gånger';
    },
    availableColors(){
        return ColorSettings.COLORS;
    },
    audioHelpActive(){
        return this.$store.state.audioHelpActive;
    },
    titleHelpActive(){
        return this.$store.state.titleHelpActive;
    }
  },
  methods: {
    toggleAudioHelpActive(){
        this.$store.commit('setAudioHelpActive', !this.$store.state.audioHelpActive);
    },
    toggleTitleHelpActive(){
        this.$store.commit('setTitleHelpActive', !this.$store.state.titleHelpActive);
    },
    toggleMute(){
        this.$store.commit('setVolumeMute', !this.$store.state.settings.volumeMute);
    },
    setCheckColor(color){
        this.$store.commit('setCheckColor', color);
    },
    isCurrentCheckColor(colorName){
        return colorName === this.$store.state.settings.checkColor;
    },
    squareIndex(row, col){
      var index = ((row-1) * this.size) +(col-1);
      return index;
    },        
      getSquaresDataForSquare(row, col){
        //row and col starts at 1
        var index = this.squareIndex(row, col);
        if(index <= this.gameSquares.length){
            return this.gameSquares[index];
        }
      },
      isSquareChecked(row, col){
        var squareData = this.getSquaresDataForSquare(row, col);
        return this.checkedSquares.indexOf(squareData.name) >= 0;
      },      
      onBingoSquareClick(squareData){
          if(this.audioHelpActive){
              this.playSquareAudio(squareData);
          } else {
              this.toggleChecked(squareData);
          }
      },
      playSquareAudio(squareData) {
        this.sound.src = "/sound/square/" +squareData.audio;
        console.log(this.sound.src);
        this.sound.play();
        this.$store.commit('setAudioHelpActive', false);
      },
      toggleChecked(squareData){
        var numberOfBingosBefore = this.numberOfBingos;
        if(this.checkedSquares.indexOf(squareData.name) < 0){
            this.playCheckSound();
        }
        this.$store.dispatch('toggleCheckedSquare', {
            name: squareData.name
        });
        if(this.numberOfBingos > numberOfBingosBefore){
            this.playBingoSound();
            this.showBingoText();
        }
        this.$store.commit('setGameFinished', this.superBingo);
      },
      playCheckSound(){
        if(!this.volumeMute){
            this.checkSound.src = "/sound/short-success-sound-glockenspiel-treasure-video-game-6346.mp3";
            this.checkSound.play();
        }
      },
      playBingoSound(){
        function generateRandomInteger(min, max) {
            return Math.floor(min + Math.random()*(max - min + 1))
        }
        if(!this.volumeMute){
            let src = "/sound/bingo/bingo" +generateRandomInteger(1, 13) +".m4a";
            if(this.superBingo){
                src = "/sound/bingo/full" +generateRandomInteger(1, 5) +".m4a";

            }
            this.sound.src = src;
            this.sound.play();
        }
      },
      showBingoText(){
        this.bingoTextClass = 'show';
        setTimeout(() => this.bingoTextClass = '', 1000);
      }
    },
   beforeRouteLeave(to, from, next) {
       this.$store.commit('setAudioHelpActive', false);
       next();
    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

$border-width:3px;
$border-color:#333;

.bingo-squares {
    display: flex;
    justify-content: center;
    width:100%;
    max-width: 600px;
    margin:auto;
    justify-content: center;
    &-container {
        padding:0 8px;
    }
    
    .bingo-column {
        display:flex;
        flex-direction: column;
        flex-grow: 0;
        width:20%;        
        .bingo-square:last-child {
            border-bottom-width:$border-width;
        }
    }

    &[data-columns="3"] {
        .bingo-column {
            width:33.33%;
        }
    }
    &[data-columns="4"] {
        .bingo-column {
            width:25%;
        }
    }
    &[data-columns="5"] {
        .bingo-column {
            width:20%;
        }
    }
    &[data-columns="6"] {
        .bingo-column {
            width: calc(100% / 6);
        }
    }
    &[data-columns="7"] {
        .bingo-column {
            width: calc(100% / 7);
        }
    }    

    .bingo-column:last-child {
     .bingo-square{
         border-right-width:$border-width;
     }   
    }
}

.bingo-square {
    flex: 1 0 auto;
    height: auto;
}

.bingo-square::before {
  content: '';
  display: block;
  padding-top: 100%;
}

.color-selector-group {
    margin-top:30px;
    display:flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.color-selector {
    width:45px;
    height:45px;
    border-radius: 50%;
    background:#ccc;
    border:3px solid #bbb;
    margin-right: 10px;
    margin-bottom: 10px;  
    display:flex;
    justify-content: center;
    align-items: center;
    //opacity: 0.5;
    transition:all 200ms ease;
    &--active, &:hover, &:focus {
        opacity: 1;
        width:50px;
        height:50px;        
    }  
    i {
        color:#fff;
        font-size: 1.5rem;
    }
}
.help-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top:10px;
    gap:15px;
}
.help-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 40px;
}
.help-button {
    border-radius: 50%;
    height: 60px;
    width: 60px;    
    background: #FFF;
    border: 3px solid #000;
    opacity:0.5;
    transition:all 200ms ease;
    &--active {
        opacity: 1;
    }
}
.speaker-button {   
    img {
        position:relative;
        left:-5px;
        width:50px;
        height:auto;
    }
}
.speaker-help-text {
    margin:10px 0 0;
    display: flex;
    gap: 10px;
    align-items: center;
    img {
        width: 20px;
    }    
}
.title-button {
    font-weight: 600;
    font-size: 18px;   
}
.bingo-text {
    position:fixed;
    top:0px;
    left:0px;
    width:100%;
    height:100%;
    z-index:20;
    display:flex;
    justify-content: center;
    align-items: center;
    font-size:0px;
    font-weight: bold;
    color:#333;
    -webkit-text-stroke: 6px rgba(20,20,20, 0.9);
    transition: all 200ms ease-out;
    &.show {
        font-size:28vw;
    }
    .b { color:#70d6ff;}
    .i { color:#ff70a6;}
    .n { color:#ff9770;}
    .g { color:#ffd670;}
    .o { color:#e9ff70;}
    pointer-events: none;
}

.bingo-information {
    margin-top:60px;
    font-size: 2rem;
}

.volume-settings {
    display:flex;
    width:100%;
    justify-content: flex-end;
    margin-bottom: 10px;
    button {
        margin:0 20px;
        font-size:2rem;
        border:none;
        box-shadow: none;
        padding:5px;
        background:transparent; 
    }
}


</style>



<template>
  <div class="bingo-square" v-bind:class="classObject" role="button" :title="altText" v-on:click="toggleCheck" :data-row="row" :data-col="col" :data-checked="checked" :style="{'background-color':squareBackgroundColor}">
    <div class="bingo-square__content">
      <div class="bingo-square__image-container">
          <img :alt="altText" class="bingo-square__image" :src="imageUrl">
          <div v-if="checked">
              <svg v-if="checkType == 'crossover'" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2" class="bingo-square__check">
                  <circle class="path circle" fill="rgba(208, 96, 121, 0.9)" stroke="#D06079" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
                  <line class="path line" fill="none" stroke="#fff" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3"/>
                  <line class="path line" fill="none" stroke="#fff" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2"/>
              </svg>
              <svg v-if="checkType == 'check'" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2" class="bingo-square__check">
                  <circle class="path circle" :fill="checkFillColor" :stroke="checkStrokeColor" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
                  <polyline class="path check" fill="none" stroke="#fff" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 "/>
              </svg>
          </div>
      </div>    
      <div v-show="showTitle" class="bingo-square__title">
          {{squareData.title}}
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'BingoSquare',
  props: {
    row: Number,
    col: Number,
    squareIndex: Number,
    squareData: Object,
    showTitle: Boolean,
    initialChecked: Boolean,
    checkType: String,
  },
  data: function () {
    return {
      checked: this.initialChecked,
      checkColor: '#fff',
      checkBackgroundColor: 'rgba(208, 96, 121, 0.9)'
    }
  },
  computed: {
      
      imageUrl(){
          return '../img/square/' +this.squareData.image;
      },
      altText(){
          return this.squareData.title;
      },
      bingoRows(){
        return this.$store.getters.bingoRows;
      },
      bingoCols(){
        return this.$store.getters.bingoCols;
      },
      bingoDiagonals(){
        return this.$store.getters.bingoDiagonals;
      },
      superBingo(){
        return this.$store.getters.bingoRows.length === this.size;
      },
      isPartOfBingo(){
        var bingoByRow = this.bingoRows.indexOf(this.row) >= 0;
        var bingoByCol = this.bingoCols.indexOf(this.col) >= 0;
        var bingoByDiagonal = false;
        var leftTopRightBottomIndexes = this.$store.getters.diagonalLeftTopRightBottomIndexes;
        var rightTopLeftBottomIndexes = this.$store.getters.diagonalRightTopLeftBottomIndexes;
        if(this.bingoDiagonals.indexOf('LEFT-TOP') >= 0 && leftTopRightBottomIndexes.indexOf(this.squareIndex) >= 0){
            bingoByDiagonal = true;
        }
        if(this.bingoDiagonals.indexOf('RIGHT-TOP') >= 0 && rightTopLeftBottomIndexes.indexOf(this.squareIndex) >= 0){
            bingoByDiagonal = true;
        } 
        return bingoByRow || bingoByCol || bingoByDiagonal;
      },
      classObject: function () {             
        return {
          'bingo-square--with-title': this.showTitle,
          'bingo': this.isPartOfBingo
        }
      },
      checkFillColor(){
        return this.$store.getters.currentCheckColor.fill;
      },
      checkStrokeColor(){
        return this.$store.getters.currentCheckColor.border;
      },
      squareBackgroundColor(){
        return this.isPartOfBingo ? this.$store.getters.currentCheckColor.bingoBackground : '#fff';
      },
      audioHelpActive(){
          return this.$store.state.audioHelpActive;
      }                 
  },
  methods: {
      toggleCheck(){
        if(!this.audioHelpActive){
          this.checked = !this.checked;
        }
        this.$emit('onClick', this.squareData);
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.bingo-square__check {
    position: absolute;
    top:10%;
    left:10%;
    width:80%;
    height: 80%;    
}

//CROSSOVER
.path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
  &.circle {
    -webkit-animation: dash .4s ease-in-out;
    animation: dash .4s ease-in-out;
  }
  &.line {
    stroke-dashoffset: 1000;
    -webkit-animation: dash .4s .35s ease-in-out forwards;
    animation: dash .4s .35s ease-in-out forwards;
  }
  &.check {
    stroke-dashoffset: -100;
    -webkit-animation: dash-check .4s .35s ease-in-out forwards;
    animation: dash-check .4s .35s ease-in-out forwards;
  }
}

p {
  text-align: center;
  margin: 20px 0 60px;
  font-size: 1.25em;
  &.success {
    color: #73AF55;
  }
  &.error {
    color: #D06079;
  }
}


@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@-webkit-keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}

@keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}

// .checkmark__circle {
//   stroke-dasharray: 166;
//   stroke-dashoffset: 166;
//   stroke-width: 2;
//   stroke-miterlimit: 10;
//   stroke: #7ac142;
//   fill: none;
//   animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
// }

// .checkmark {
//   position: absolute;
//   top:0px;
//   left:10%;
//   width: 80%;
//   height: 80%;
//   border-radius: 50%;
//   display: block;
//   stroke-width: 2;
//   stroke: #fff;
//   stroke-miterlimit: 10;
//   margin: 10% auto;
//   box-shadow: inset 0px 0px 0px #7ac142;
//   animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
// }

// .checkmark__check {
//   transform-origin: 50% 50%;
//   stroke-dasharray: 48;
//   stroke-dashoffset: 48;
//   animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
// }

// @keyframes stroke {
//   100% {
//     stroke-dashoffset: 0;
//   }
// }
// @keyframes scale {
//   0%, 100% {
//     transform: none;
//   }
//   50% {
//     transform: scale3d(1.1, 1.1, 1);
//   }
// }
// @keyframes fill {
//   100% {
//     box-shadow: inset 0px 0px 0px 50px #7ac142;
//   }
// }

</style>

<style scoped lang="scss">

$border-width:3px;
$border-color:#333;

.bingo-square {
    position: relative;
    transition:all 200ms ease;
    flex-shrink: 0;    
    border:$border-width solid $border-color;
    border-right-width:0px;
    border-bottom-width:0px;
    background-color: #fff;
    display:flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    &__image-container {
        width:100%;
        height:30%;
        //height:100%;
        //height:100px;   
        display: flex;
        justify-content: center;
        align-items: center;       
        flex-shrink: 1;    
        flex-grow: 1;    
    }
    &__image {
       //margin-top:10%;
        max-width:80%;
        max-height:80%;
    }
    &__content {
        position: absolute;
        top: 0; left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
    }
    &__title {
      font-size: 14px;
    }
    img, .bingo-square__check {
        transition:all 200ms ease;
    }
    &:hover {
        img, .bingo-square__check {
            transform: scale(1.05);
        }
    }
    &.bingo {
        background:#ccc;
    }
}

.bingo-squares {
  &[data-columns="3"] {
    .bingo-square__title {
      font-size: 18px;
    }
  }
  &[data-columns="4"] {
    .bingo-square__title {
      font-size: 16px;
    }
  }
  &[data-columns="5"] {
    .bingo-square__title {
      font-size: 14px;
    }
  }
  &[data-columns="6"] {
    .bingo-square__title {
      font-size: 13px;
    }
  }
  &[data-columns="7"] {
    .bingo-square__title {
      font-size: 12px;
    }
  }
}


</style>

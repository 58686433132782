import { createStore } from 'vuex'
import ColorSettings from '../color-settings.js'

export default createStore({
  state: {
    settings: {
      colAndRowSize: 5,
      excludedSquares: [],
      checkColor: 'yellow',
      volumeMute: false,
    },
    game: {
      squares: [],
      checkedSquares: [],
      finished: true,
    },
    audioHelpActive: false,
    titleHelpActive: false
  },
  mutations: {
    saveColAndRowSize: (state, size) => {
        localStorage.setItem('size', size);
       {state.settings.colAndRowSize = size;}
    },
    initDataFromLocalStorage: (state) => {
      //Col and row size
      if (localStorage.getItem('size')) {
        {state.settings.colAndRowSize = parseInt(localStorage.getItem('size'))}
      } 

      //Check color
      if (localStorage.getItem('checkColor')) {
        {state.settings.checkColor = localStorage.getItem('checkColor')}
      } 
      
      //Excluded squares
      if (localStorage.getItem('excludedSquares')) {
        {state.settings.excludedSquares = JSON.parse(localStorage.getItem('excludedSquares'))}
      }       
      
      //Game squares
      if (localStorage.getItem('gameSquares')) {
        {state.game.squares = JSON.parse(localStorage.getItem('gameSquares'))}
      }
      //Checked squares
      if (localStorage.getItem('checkedSquares')) {
        {state.game.checkedSquares = JSON.parse(localStorage.getItem('checkedSquares'))}
      }
      //Finished
      if (localStorage.getItem('finished')) {
        {state.game.finished = JSON.parse(localStorage.getItem('finished'))}
      }

      //Volume mute
      if (localStorage.getItem('volumeMute')) {
        {state.settings.volumeMute = JSON.parse(localStorage.getItem('volumeMute'))}
      }      
    }, 
    addToExcludedSquares: (state, payload) => {
      state.settings.excludedSquares.push(payload.name);
      localStorage.setItem('excludedSquares', JSON.stringify(state.settings.excludedSquares));
    },       
    removeFromExcludedSquares: (state, payload) => {
      state.settings.excludedSquares.splice(state.settings.excludedSquares.indexOf(payload.name), 1);
      localStorage.setItem('excludedSquares', JSON.stringify(state.settings.excludedSquares));
    },
    setGameSquares: (state, payload) => {
      state.game.squares = payload;
      localStorage.setItem('gameSquares', JSON.stringify(state.game.squares));
    },
    setGameFinished: (state, payload) => {
      state.game.finished = payload;
      localStorage.setItem('finished', JSON.stringify(state.game.finished));
    },
    addToCheckedSquares: (state, payload) => {
      state.game.checkedSquares.push(payload.name);
      localStorage.setItem('checkedSquares', JSON.stringify(state.game.checkedSquares));
    },       
    removeFromCheckedSquares: (state, payload) => {
      state.game.checkedSquares.splice(state.game.checkedSquares.indexOf(payload.name), 1);
      localStorage.setItem('checkedSquares', JSON.stringify(state.game.checkedSquares));
    },
    clearCheckedSquares: (state) => {
      state.game.checkedSquares = [];
      localStorage.setItem('checkedSquares', JSON.stringify(state.game.checkedSquares));
    },
    setCheckColor: (state, payload) => {
      state.settings.checkColor = payload;
      localStorage.setItem('checkColor', state.settings.checkColor);
    },    
    setAudioHelpActive: (state, payload) => {
      state.audioHelpActive = payload;
    },    
    setTitleHelpActive: (state, payload) => {
      state.titleHelpActive = payload;
    },    
    setVolumeMute: (state, payload) => {
      state.settings.volumeMute = payload;
      localStorage.setItem('volumeMute', JSON.stringify(state.settings.volumeMute));
    },    
  },
  actions: {
    toggleExcludeSquare (context, payload) {
      if(context.state.settings.excludedSquares.indexOf(payload.name) >= 0){
        context.commit('removeFromExcludedSquares', payload);
      } else {
        context.commit('addToExcludedSquares', payload);
      }
    },
    toggleCheckedSquare (context, payload) {
      if(context.state.game.checkedSquares.indexOf(payload.name) >= 0){
        context.commit('removeFromCheckedSquares', payload);
      } else {
        context.commit('addToCheckedSquares', payload);
      }
    },
  },
  modules: {
  },
  getters: {
    currentCheckColor: state => {
      for(var i=0; i < ColorSettings.COLORS.length; i++){
        if(state.settings.checkColor === ColorSettings.COLORS[i].name){
          return ColorSettings.COLORS[i];
        }
      }
      return ColorSettings.COLORS[0];
    },
    bingoRows: state => {
      var bingoRows = [];
      var checkedSquares = state.game.checkedSquares;
      var squares = state.game.squares;
      var size = Math.sqrt(squares.length);            
      for(var i=1; i<= size; i++){
        var allChecked = true;
        for(var j=1; j<= size; j++){
          var index = ((i-1) * size) +(j-1);
          var name = squares[index].name;
          if(checkedSquares.indexOf(name) < 0){
            allChecked = false;
          }
        }
        if(allChecked){
          bingoRows.push(i);
        }
      }
      return bingoRows;
    },
    bingoCols: state => {
      var bingoCols = [];
      var checkedSquares = state.game.checkedSquares;
      var squares = state.game.squares;
      var size = Math.sqrt(squares.length);
      for(var i=1; i<= size; i++){
        var allChecked = true;
        for(var j=1; j <= size; j++){
          var index = ((j-1) * size) +(i-1);
          var name = squares[index].name;
          if(checkedSquares.indexOf(name) < 0){
            allChecked = false;
          }
        }
        if(allChecked){
          bingoCols.push(i);
        }
      }
      return bingoCols;
    },
    diagonalLeftTopRightBottomIndexes: state => {
      var leftTopRightBottomIndexes = []; 
      var squares = state.game.squares;
      var size = Math.sqrt(squares.length);      
      for(var i=1; i<= size; i++){
        var index = (i-1)*size +(i-1);
        leftTopRightBottomIndexes.push(index);              
      }
      return leftTopRightBottomIndexes;
    },
    diagonalRightTopLeftBottomIndexes: state => {
      var rightTopLeftBottomIndexes = []; 
      var squares = state.game.squares;
      var size = Math.sqrt(squares.length);      
      for(var i=1; i<= size; i++){
        var index = i*(size-1);
        rightTopLeftBottomIndexes.push(index);              
      }
      return rightTopLeftBottomIndexes;
    },
    bingoDiagonals: (state, getters) => {
      var bingoDiagonals = [];
      var checkedSquares = state.game.checkedSquares;
      var squares = state.game.squares;
      var leftTopRightBottomIndexes = getters.diagonalLeftTopRightBottomIndexes;
      var rightTopLeftBottomIndexes = getters.diagonalRightTopLeftBottomIndexes;

      //LEFT TOP
      var allChecked = true;
      leftTopRightBottomIndexes.forEach(function(index){
        var name = squares[index].name;
        if(checkedSquares.indexOf(name) < 0){
          allChecked = false;
        }
      });  
      if(allChecked){
        bingoDiagonals.push("LEFT-TOP");
      }    
      

      //RIGHT TOP
      allChecked = true;
      rightTopLeftBottomIndexes.forEach(function(index){
        var name = squares[index].name;
        if(checkedSquares.indexOf(name) < 0){
          allChecked = false;
        }
      });  
      if(allChecked){
        bingoDiagonals.push("RIGHT-TOP");
      }    
            
      return bingoDiagonals;
    },

  }  
})

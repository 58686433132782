import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Bingo from '../views/Bingo.vue'
import Rules from '../views/Rules.vue'

const DEFAULT_TITLE = 'Barnens bilbingo - Spela bilbingo på din telefon eller surfplatta';
const DEFAULT_TITLE_SUFFIX = ' - Barnens bilbingo';
const DEFAULT_DESCRIPTION = 'Spela bilbingo på din telefon eller surfplatta - gratis och utan reklam. Gör resan roligare för dina barn! Du väljer själv hur stora bricka du vill och vilka saker du vill ha med.';
const DEFAULT_DESCRIPTION_SUFFIX = ' Spela bilbingo på din telefon eller surfplatta - gratis och utan reklam. Gör resan roligare för dina barn!';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: DEFAULT_TITLE,
      description: DEFAULT_DESCRIPTION
    }    
  },
  {
    path: '/bingo',
    name: 'Bingo',
    component: Bingo,
    meta: {
      title: 'Min bingobricka' +DEFAULT_TITLE_SUFFIX,
      description: 'Min bingobricka.' +DEFAULT_DESCRIPTION_SUFFIX
    }
  },
  {
    path: '/vad-ar-bilbingo',
    name: 'Rules',
    component: Rules,
    meta: {
      title: 'Vad är bilbingo?' +DEFAULT_TITLE_SUFFIX,
      description: 'Bilbingo är ett spel som man kan spela när man åker bil. Det går i korthet ut på att hålla utkik efter olika föremål längs resan. När du ser ett föremål som finns på din bingo-bricka så kryssar du den rutan. När du sedan har kryssat alla föremål på en rad, kolumn eller diagonal har du fått Bingo!' +DEFAULT_DESCRIPTION_SUFFIX
    }    
  },
  {
    path: '/om',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta: {
      title: 'Om' +DEFAULT_TITLE_SUFFIX,
      description: 'Denna webpplats skapades av mig (Gustav Jappevik) för att mina barn på ett enkelt sätt skulle kunna spela Bilbingo på våra bilresor. Webbplatsen innehåller ingen reklam och är säker att använda för barn.' +DEFAULT_DESCRIPTION_SUFFIX
    }    
  },
  {
    path: '/bilbingo-som-app',
    name: 'MobileApp',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "mobileApp" */ '../views/MobileApp.vue'),
    meta: {
      title: 'Bilbingo som app' +DEFAULT_TITLE_SUFFIX,
      description: 'Du kan installera Barnens bilbingo som en app på din hemskärm i din telefon eller surfplatta. På detta sätt kommer du att få en appikon på din hemskärm som ser ut som vilken annan app som helst och Barnens bilbingo kommer att öppnas som en egen app.' +DEFAULT_DESCRIPTION_SUFFIX
    }    
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  },  
  routes
})

router.beforeEach((to) => {
  document.title = to.meta.title || DEFAULT_TITLE;

  document
  .querySelector('meta[property="og:title"]')
  .setAttribute('content',to.meta.title || DEFAULT_TITLE)  

  document
  .getElementsByTagName('meta')
  .namedItem('description')
  .setAttribute('content',to.meta.description || DEFAULT_DESCRIPTION)  

  document
  .querySelector('meta[property="og:description"]')
  .setAttribute('content',to.meta.description || DEFAULT_DESCRIPTION)  

});

export default router

<template>
  <div class="rules">
    <h1>Vad är bilbingo?</h1>
    <p class="mobile-padding">
      Bilbingo är ett spel som man kan spela när man åker bil. Det går i korthet ut på att hålla utkik efter olika föremål längs resan. När du ser ett föremål som finns på din bingo-bricka så kryssar du den rutan. När du sedan har kryssat alla föremål på en rad, kolumn eller diagonal har du fått Bingo!      
      <br><br>
      Det går att spela bilbingo själv eller mot andra. Om ni är flera spelare så behöver ni varsin bingobricka och sedan bestämmer ni när man vinner, antingen vid första bingoraden, vid fem bingorader, full bricka eller något annant ni själva hittar på. För att göra det lite svårare kan ni bestämma att endast en person får ta varje föremål ni ser. En person ropar då ut till exempel "Hund" när hen ser en hund och då får ingen annan spelare ta samma hund.
      <br><br><br><br>
      Pssst! Om antalet telefoner och surfplattor inte räcker till eller om ni hellre vill spela med papper och penna går det bra att skapa bingobrickor och sedan skriva ut dessa.
    </p>
  </div>
</template>

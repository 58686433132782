const COLORS = [
    {
        name: 'yellow',
        title: 'Gul',
        fill: 'rgba(255, 255, 0, 0.95)',
        bingoBackground: 'rgba(255, 255, 0, 0.5)',
        border: '#e5e500',
    },  
    {
        name: 'yellow-dark',
        title: 'Mörkgul',
        fill: 'rgba(255, 215, 0, 0.95)',
        bingoBackground: 'rgba(255, 215, 0, 0.5)',
        border: '#e5c100',
    },    
    {
        name: 'gold',
        title: 'Guld',
        fill: 'rgba(213, 176, 56, 0.95)',
        bingoBackground: 'rgba(213, 176, 56, 0.5)',
        border: '#c7a22a',
    },    
    {
        name: 'green',
        title: 'Grön',
        fill: 'rgba(115, 175, 85, 0.95)',
        bingoBackground: 'rgba(115, 175, 85, 0.5)',
        border: '#669f4a',
    },  
    {
        name: 'red',
        title: 'Röd',
        fill: 'rgba(255, 0, 0, 0.95)',
        bingoBackground: 'rgba(255, 0, 0, 0.5)',
        border: '#e50000',
    },  
    {
        name: 'pink',
        title: 'Rosa',
        fill: 'rgba(255, 192, 203, 0.95)',
        bingoBackground: 'rgba(255, 192, 203, 0.5)',
        border: '#ff93a6',
    },  
    {
        name: 'pink-bubbelgum',
        title: 'Rosa',
        fill: 'rgba(253, 93, 168, 0.95)',
        bingoBackground: 'rgba(253, 93, 168, 0.5)',
        border: '#fc3a95',
    },  
    {
        name: 'pink-hotpink',
        title: 'Rosa',
        fill: 'rgba(255, 22, 148, 0.95)',
        bingoBackground: 'rgba(255, 22, 148, 0.5)',
        border: '#f90086',
    },  
    {
        name: 'blå',
        title: 'Blå',
        fill: 'rgba(0, 0, 255, 0.85)',
        bingoBackground: 'rgba(0, 0, 255, 0.5)',
        border: '#0000e5',
    },
    {
        name: 'blue-light',
        title: 'Ljusblå',
        fill: 'rgba(79, 195, 247, 0.95)',
        bingoBackground: 'rgba(79, 195, 247, 0.5)',
        border: '#2fb8f5',
    },
    {
        name: 'gray',
        title: 'Grå',
        fill: 'rgba(40, 40, 40, 0.88)',
        bingoBackground: 'rgba(40, 40, 40, 0.28)',
        border: '#333',
    },
    {
        name: 'black',
        title: 'Svart',
        fill: 'rgba(0, 0, 0, 0.88)',
        bingoBackground: 'rgba(0, 0, 0, 0.28)',
        border: '#000',
    },          
];

export default {
    COLORS: COLORS,
}